<template>
  <section class="not-found">
    <div class="not-found-box">
      <img src="@/images/common/404.png" width="500" height="284" alt="404" />
      <div class="not-found-cont">
        <h2>NOT FOUND</h2>
        <a class="not-found-back" href="javascript:;" @click="backHome">Back Home</a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    backHome() {
      this.$router.push('/index')
    }
  }
}
</script>